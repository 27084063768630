.PreviewGenerate {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  overflow: hidden;
  @media (max-width: 800px) {
    gap: 20px;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    .first {
      max-width: 1040px;
      font-size: 40px;
      text-align: center;
    }
    .second {
      max-width: 840px;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      color: #6a6a6a;
    }
    .third {
      max-width: 640px;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      color: #757A88;
    }
    .fourth {
      font-size: 32px;
      font-weight: 400;
      color: #6a6a6a;
      text-align: center;
    }
    @media (max-width: 1020px) {
      //bottom: 0;
      .first {
        max-width: 640px;
        font-size: 40px;
      }
      .second {
        max-width: 640px;
        font-size: 18px;
      }
      .third {
        display: none;
      }
      .fourth {
        font-size: 24px;
      }
    }
    @media (max-width: 800px) {
      gap: 16px;
      .first {
        max-width: 90%;
        font-size: 20px;
      }
      .second {
        max-width: 90%;
        font-size: 16px;
      }
      .fourth {
        font-size: 16px;
      }
    }
  }
}

[data-theme="dark"] {
  .PreviewGenerate {
    .text {
      .fourth, .second {
        color: #BDBDBD;
      }
    }
  }
}